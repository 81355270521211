<template>
  <div>
    <alert-store-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      :key="filtersModalKey"
      @apply="applyFilters"
    />
    <h2 class="view-header">Alerts</h2>
    <div class="flex align-items-center justify-content-end gap-2 mb-2">
      <a-button-group>
        <a-tooltip>
          <template slot="title"> Open Filters </template>
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showFilterModal"
          />
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> Reset Filters </template>
          <a-button
            icon="undo"
            :disabled="loading || disableResetButton"
            @click="resetFilters"
          />
        </a-tooltip>
      </a-button-group>
      <a-date-picker
        v-model="dateFrom"
        placeholder="Date From"
        :disabled="loading"
        :format="visualDateFormat"
      />
      <a-date-picker
        v-model="dateTo"
        placeholder="Date To"
        :disabled="loading"
        :format="visualDateFormat"
      />
    </div>
    <a-table
      size="small"
      row-key="id"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
    >
      <span slot="receiver" slot-scope="text, record">
        {{
          record.receiver
            ? record.receiver.first_name + " " + record.receiver.last_name
            : "N/A"
        }}
      </span>
      <span slot="type" slot-scope="text, record">
        {{ convertAlertTypeToText(record.type) }}
      </span>
      <span slot="severity" slot-scope="text, record">
        <a-tag :color="getTagColor(record.severity)">
          {{ convertAlertSeverityToText(record.severity) }}
        </a-tag>
      </span>
      <span slot="createdAt" slot-scope="text, record">
        {{
          Util.convertDateTimeToCurrentTimeZoneFormattedString(
            record.created_at
          )
        }}
      </span>
    </a-table>
  </div>
</template>
<script>
import {
  Table,
  Input,
  Button,
  Tag,
  DatePicker,
  notification,
  Modal,
  Tooltip,
} from "ant-design-vue";

import {
  alertSeverityColorsMap,
  alertSeverityMap,
  alertTypeMap,
} from "@/const";
import api from "@/api";

import moment from "moment-timezone";

import AssociateProfile from "@/components/associate-profile.vue";
import AlertStoreFilters from "@/components/alert-store-filters.vue";
import Util from "@/util";

const filtersBaseState = Object.freeze({
  alertType: undefined,
  alertSeverity: undefined,
});

export default {
  components: {
    "alert-store-filters": AlertStoreFilters,
    "associate-profile": AssociateProfile,
    "a-table": Table,
    "a-input": Input,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tooltip": Tooltip,
    "a-tag": Tag,
    "a-date-picker": DatePicker,
    "a-modal": Modal,
  },
  mixins: [api],
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "Type",
          scopedSlots: { customRender: "type" },
          align: "center",
          sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
          title: "Severity",
          key: "severity",
          scopedSlots: { customRender: "severity" },
          sorter: (a, b) => a.severity.localeCompare(b.severity),
          align: "center",
        },
        {
          title: "Title",
          dataIndex: "title",
          align: "center",
        },
        {
          title: "Details",
          dataIndex: "details",
          align: "left",
        },
        {
          title: "Receiver",
          scopedSlots: { customRender: "receiver" },
          align: "center",
        },
        {
          title: "Created At",
          key: "created_at",
          sorter: (a, b) =>
            moment(a.created_at).unix() - moment(b.created_at).unix(),
          scopedSlots: { customRender: "createdAt" },
        },
      ],
      dateFrom: undefined,
      dateTo: undefined,
      loading: false,
      filters: { ...filtersBaseState },
      filtersModalVisible: false,
      filtersModalKey: 0,
    };
  },
  watch: {
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {
    Util() {
      return Util;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverFormatDateFrom() {
      if (!this.dateFrom) {
        return undefined;
      }

      return this.dateFrom.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    },
    serverFormatDateTo() {
      if (!this.dateTo) {
        return undefined;
      }

      return this.dateTo.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      this.apiGetAllProjectAlerts(
        this.filters.alertType,
        this.filters.alertSeverity,
        this.serverFormatDateFrom,
        this.serverFormatDateTo
      )
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Error",
              description:
                "An error occurred while receiving associate activity. Please try again later",
            });
          }

          this.tableData = data.items;
        })
        .finally(() => (this.loading = false));
    },
    convertAlertTypeToText(alertType) {
      return alertTypeMap[alertType] ?? alertType;
    },
    convertAlertSeverityToText(alertSeverity) {
      return alertSeverityMap[alertSeverity] ?? alertSeverity;
    },
    getTagColor(severity) {
      return alertSeverityColorsMap[severity] ?? "#808080";
    },

    applyFilters(filters) {
      this.hideFilterModal();
      this.filters = { ...filters };
    },
    showFilterModal() {
      this.filtersModalVisible = true;
    },
    hideFilterModal() {
      this.filtersModalVisible = false;
    },
    resetFilters() {
      this.filters = { ...filtersBaseState };
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
