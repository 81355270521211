<template>
  <div>
    <time-off-requests-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      :key="filtersModalKey"
      @apply="applyFilters"
    />
    <time-off-request-modal
      :key="timeOffRequestKey"
      :time-off-request="currentTimeOffRequest"
      @resolved="resolvedRequest"
      @close="closeRequest"
    />
    <a-modal
      title="Associate Profile"
      width="80%"
      :footer="null"
      v-model="associateProfileModalVisible"
      @ok="closeAssociateProfile"
      @cancel="closeAssociateProfile"
    >
      <div style="overflow-x: auto; width: 100%">
        <associate-profile
          :associate-number="associateNumberToShow"
          @edited="closeAssociateProfile"
        />
      </div>
    </a-modal>
    <h2 class="view-header">Time Off Requests</h2>
    <div class="flex align-items-center justify-content-end gap-2 mb-2">
      <a-button-group>
        <a-tooltip>
          <template slot="title"> Open Filters </template>
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showFilterModal"
          />
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> Reset Filters </template>
          <a-button
            icon="undo"
            :disabled="loading || disableResetButton"
            @click="resetFilters"
          />
        </a-tooltip>
      </a-button-group>
      <a-date-picker
        v-model="dateFrom"
        placeholder="Date From"
        :disabled="loading"
        :format="visualDateFormat"
      />
      <a-date-picker
        v-model="dateTo"
        placeholder="Date To"
        :disabled="loading"
        :format="visualDateFormat"
      />
    </div>
    <a-table
      size="small"
      row-key="id"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
    >
      <span slot="requesting-dates" slot-scope="text, record">
        {{ formatDates(record.dates) }}
      </span>
      <span slot="type" slot-scope="text, record">
        <a-tag :color="getTagColor(record.type)">
          {{ convertTypeToText(record.type) }}
        </a-tag>
      </span>
      <span slot="status" slot-scope="text, record">
        {{ convertStatusToText(record.status) }}
      </span>
      <span
        slot="associate"
        slot-scope="text, record"
        :key="record.associate.photo_url"
      >
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
        />
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group>
          <a-tooltip>
            <template slot="title"> Open Request </template>
            <a-button
              icon="folder-open"
              size="small"
              @click="openTimeOffRequest(record)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Profile </template>
            <a-button
              icon="user"
              size="small"
              @click="openAssociateProfile(record.associate.associate_number)"
            />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>
<script>
import {
  Table,
  Input,
  Button,
  Tag,
  DatePicker,
  notification,
  Modal,
  Tooltip,
} from "ant-design-vue";

import {
  timeOffRequestStatusMap,
  timeOffRequestTypeColorMap,
  timeOffRequestTypeMap,
} from "@/const";
import api from "@/api";
import Util from "@/util";
import AssociateProfile from "@/components/associate-profile.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import TimeOffRequestsFilters from "@/views/time-off-requests-filters.vue";
import TimeOffRequestModal from "@/components/time-off-request-modal.vue";

const filtersBaseState = Object.freeze({
  associate: undefined,
  type: undefined,
  status: undefined,
});

export default {
  components: {
    "grouped-associate-info": GroupedAssociateInfo,
    "time-off-requests-filters": TimeOffRequestsFilters,
    "time-off-request-modal": TimeOffRequestModal,
    "associate-profile": AssociateProfile,
    "a-table": Table,
    "a-input": Input,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tag": Tag,
    "a-date-picker": DatePicker,
    "a-modal": Modal,
    "a-tooltip": Tooltip,
  },
  mixins: [api],
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "Associate Number",
          dataIndex: "associate.associate_number",
          align: "center",
        },
        {
          title: "Associate",
          key: "name",
          scopedSlots: { customRender: "associate" },
          align: "center",
        },
        {
          title: "Requesting Dates",
          key: "requestingDates",
          scopedSlots: { customRender: "requesting-dates" },
          align: "center",
        },
        {
          title: "Type",
          key: "type",
          scopedSlots: { customRender: "type" },
          align: "center",
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],
      dateFrom: undefined,
      dateTo: undefined,
      loading: false,
      filters: { ...filtersBaseState },
      associateNumberToShow: undefined,
      associateProfileModalVisible: false,
      filtersModalVisible: false,
      filtersModalKey: 0,

      timeOffRequestKey: 0,
      currentTimeOffRequest: undefined,
    };
  },
  watch: {
    date() {
      this.loadData();
    },
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverFormatDateFrom() {
      if (!this.date) {
        return null;
      }

      return this.dateFrom.format("YYYY-MM-DD");
    },
    serverFormatDateTo() {
      if (!this.date) {
        return null;
      }

      return this.dateTo.format("YYYY-MM-DD");
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  methods: {
    formatDates: Util.formatDatesListForTimeOffRequestTable,
    loadData() {
      this.loading = true;

      this.apiGetAllTimeOffRequests(
        this.filters.associate,
        this.filters.type,
        this.filters.status,
        this.serverFormatDateFrom,
        this.serverFormatDateTo
      )
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Error",
              description:
                "An error occurred while receiving associate activity. Please try again later",
            });
          }

          this.tableData = data.items;
        })
        .finally(() => (this.loading = false));
    },

    convertTypeToText(timeOffType) {
      return timeOffRequestTypeMap[timeOffType] ?? timeOffType;
    },
    convertStatusToText(timeOffStatus) {
      return timeOffRequestStatusMap[timeOffStatus] ?? timeOffStatus;
    },

    getTagColor(timeOffType) {
      return timeOffRequestTypeColorMap[timeOffType] ?? "#808080";
    },
    closeAssociateProfile() {
      this.associateNumberToShow = undefined;
      this.associateProfileModalVisible = false;
    },

    applyFilters(filters) {
      this.hideFilterModal();
      this.filters = { ...filters };
    },
    showFilterModal() {
      this.filtersModalVisible = true;
    },
    hideFilterModal() {
      this.filtersModalVisible = false;
    },
    resetFilters() {
      this.filters = { ...filtersBaseState };
    },

    openAssociateProfile(number) {
      this.associateNumberToShow = number;
      this.associateProfileModalVisible = true;
    },
    openTimeOffRequest(record) {
      ++this.timeOffRequestKey;
      this.currentTimeOffRequest = record;
    },

    closeRequest() {
      this.currentTimeOffRequest = undefined;
    },
    resolvedRequest() {
      this.closeRequest();
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
