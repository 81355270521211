<template>
  <div class="flex flex-column gap-3">
    <a-select
      v-model="selectedDeclineReason"
      placeholder="Select Reason"
      style="width: 100%"
      @change="handleReasonChange"
      :dropdownMatchSelectWidth="false"
      :dropdownStyle="{ width: '500px' }"
    >
      <a-select-option
        v-for="(text, key) in processedOptions"
        :key="key"
        :value="key"
        class="custom-dropdown-option"
      >
        {{ text }}
      </a-select-option>
      <a-select-option value="custom" class="custom-dropdown-option">
        Custom Reason
      </a-select-option>
    </a-select>
    <a-textarea
      v-if="isCustomReason"
      v-model="reason"
      placeholder="Enter custom reason"
      style="margin-top: 15px"
      :auto-size="{ minRows: 2, maxRows: 5 }"
    />
    <div class="grid">
      <div class="col-6">
        <div class="flex flex-column gap-2">
          <div class="flex gap-2">
            <a-switch v-model="params.readyForPayment" />
            <span>Shift Ready For Payment</span>
          </div>
          <div class="flex gap-2">
            <a-switch v-model="params.withWriteUp" />
            <span>Send Write-Up</span>
          </div>
          <div class="flex gap-2">
            <a-switch v-model="params.shiftActionAutocomplete" />
            <span>Shift Action Autocomplete</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="flex flex-column gap-2">
          <div class="flex gap-2">
            <a-switch v-model="params.terminated" />
            <span>Terminate</span>
          </div>
          <div class="flex gap-4" v-if="!params.terminated">
            <div class="flex gap-2">
              <a-switch v-model="setSuspend" />
              <span>Suspend</span>
            </div>
            <div class="flex gap-2" v-if="setSuspend">
              <a-input-number size="small" :min="1" v-model="params.suspend" />
              <span>Days</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Input, InputNumber, Switch } from "ant-design-vue";
import api from "@/api";
import Util from "@/util";

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-textarea": Input.TextArea,
    "a-switch": Switch,
  },
  props: {
    declineReasons: {
      type: Object,
      required: false,
    },
  },
  mixins: [api],
  data() {
    return {
      selectedDeclineReason: undefined,
      reason: "",
      constants: null,

      setSuspend: false,

      params: {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false,
        shiftActionAutocomplete: false,
      },
    };
  },
  watch: {
    reason: {
      handler(newValue) {
        console.log(newValue);
        this.$emit("reason", newValue);
      },
    },
    selectedDeclineReason: {
      handler(newValue) {
        if (newValue === "custom") {
          this.reason = "";
        } else {
          this.reason = this.processedOptions[newValue];
        }
      },
    },
    setSuspend() {
      if (!this.setSuspend) {
        this.params.suspend = 0;
      } else if (this.setSuspend && this.params.suspend == 0) {
        this.params.suspend = 1;
      }
    },
    params: {
      handler() {
        if (this.params.terminated) {
          this.setSuspend = false;
          this.params.suspend = 0;
        }

        this.$emit("params", this.params);
      },
      deep: true,
    },
  },
  computed: {
    isCustomReason() {
      return this.selectedDeclineReason === "custom";
    },

    minShiftDurationValue() {
      return (
        this.$store.state.applicationState.timeConstants.min_work_duration +
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    minShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.minShiftDurationValue
      );
    },
    almostMinShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.minShiftDurationValue - 1
      );
    },

    longShiftDurationValue() {
      return (
        this.$store.state.applicationState.timeConstants.long_work_duration +
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    longShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.long_work_duration +
          this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },

    almostLongShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.longShiftDurationValue - 1
      );
    },

    firstLunchTime() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.first_lunch_time
      );
    },

    longWorkDurationHours() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.long_work_duration
      );
    },
    lunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    secondLunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.second_lunch_duration
      );
    },

    processedOptions() {
      return (
        this.declineReasons ?? {
          no_lunch_authorization: `If you work a ${this.minShiftDuration} shift or more, you must take at
          least ${this.lunchDuration} unpaid meal break. You may waive the meal break if
          you work ${this.almostMinShiftDuration} or less. You do not have authorization to work
          over ${this.minShiftDuration} and not to take the required meal break. Continued non-compliance may result in
          removal of all your shifts from the schedule and / or disciplinary actions.`,
          no_second_lunch_authorization: `If you work a ${this.longShiftDuration} shift or more, you must take
          two at least ${this.lunchDuration} unpaid meal breaks. You may waive the second meal break if you work
          ${this.almostLongShiftDuration} or less. You do not have authorization to work over ${this.longShiftDuration}
          and not to take the required meal breaks. Continued non-compliance may result in removal of all your shifts
          from the schedule and / or disciplinary actions.`,
          late_end_authorization: `You must end your shifts on time. Late end of shift may result in overtime.
          Stick to your schedule. Do not work shifts with late end shift unless you have authorization.
          Continued non-compliance may result in removal of all your shifts from the schedule
          and / or disciplinary actions.`,
          early_start_authorization: `You must start your shifts on time. Early start of shift may result in overtime.
          Stick to your schedule. Do not sign shifts with early start shift unless you have authorization.
          Continued non-compliance can result in disciplinary action.`,
          early_lunch_end_authorization: `You must take your meal break for at least ${this.lunchDuration}. You do not
          have authorization to work with a meal break shorter than ${this.lunchDuration}. Continued non-compliance
         may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
          early_second_lunch_end_authorization: `You must take your second meal break for at least ${this.lunchDuration}.
          You do not have authorization to work with a meal break shorter than ${this.lunchDuration}. Continued
          non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
          no_actual_time_authorization:
            "Adding shifts without tracked worked time constitutes a breach of company policy and can lead to payroll errors. This action disrupts accurate payroll processing and compliance with labor laws, potentially leading to financial discrepancies. ",
          overtime_authorization: `You can't work overtime. Stick to your schedule. Continued non-compliance may result
          in removal of all your shifts from the schedule and / or disciplinary actions.`,
          edit_shift_authorization: "",
          shift_info: "",
          outside_work_area_authorization: `You should only work and track activities in the app in the assigned work
          area. Continued non-compliance may result in removal of all your shifts from the schedule
          and / or disciplinary actions.`,
          employee_issue: "",
          absence_on_shift_authorization: `Reason for your absence is unconvincing. Please provide additional
          data and submit shift again.`,
          late_start_authorization: "",
          early_end_authorization: "",
          first_lunch_period_authorization: `If you work without a break ${this.firstLunchTime} or more, you must take an unpaid
          meal break of at least ${this.lunchDuration}. You may waive the meal break if you work
          ${this.minShiftDuration} or less. Continued non-compliance may result in removal of all of your shifts from
          your schedule and/or disciplinary action.`,
          signed_actual_time_discrepancy: "",
          no_rest_break_authorization: "",
          associate_not_recognized_authorization: `You must use your Associate ID to track the shift. Using someone else's
          Associate ID may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        }
      );
    },
  },
  methods: {
    handleReasonChange(value) {
      if (value !== "custom") {
        this.reason = this.processedOptions[value];
      } else {
        this.reason = "";
      }
    },
    setAuths(selectedAuths) {
      const previousReasonKey = this.selectedDeclineReason;

      if (selectedAuths.length === 1) {
        const reasonKey = selectedAuths[0].type;

        if (this.processedOptions.hasOwnProperty(reasonKey)) {
          this.selectedDeclineReason = reasonKey;
        } else {
          this.selectedDeclineReason = "custom";
        }
      } else {
        this.selectedDeclineReason = "custom";
      }

      if (previousReasonKey === this.selectedDeclineReason) {
        this.$emit("reason", this.reason);
      }
    },
  },
};
</script>

<style>
.custom-dropdown-option {
  white-space: normal;
  word-wrap: break-word;
  min-height: 20px;
  line-height: 1.5;
  padding: 5px 12px;
}
</style>
