import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueResource from "vue-resource";
import Meta from "vue-meta";
import VueSignaturePad from "vue-signature-pad";
import Cookies from "js-cookie";
import { Modal, notification } from "ant-design-vue";
import AuthUtil from "@/auth.util";

import "vue-tour/dist/vue-tour.css";
import VueTour from "vue-tour";

Vue.use(Meta);
Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueTour);
Vue.use(Modal);
Vue.use(VueSignaturePad);

const UNAUTHORIZED = 401;

Vue.http.options.credentials = true;

Vue.http.interceptors.push((req, next) => {
  req.headers.set("Client-Timezone", store.state.applicationState.timeZone);

  const csrf = Cookies.get("csrftoken");
  if (csrf) {
    req.headers.set("X-CSRFToken", csrf);
  }
});

Vue.prototype.$tableHeight = Vue.observable({
  value: window.innerHeight - 320,
});

window.addEventListener("resize", () => {
  Vue.prototype.$tableHeight.value = window.innerHeight - 320;
});

Vue.mixin({
  mounted() {
    if (this.onKeydown) {
      window.addEventListener("keydown", this.onKeydown);
    }
  },
  beforeDestroy() {
    if (this.onKeydown) {
      window.removeEventListener("keydown", this.onKeydown);
    }
  },
});

const style = document.createElement("style");
style.innerHTML = `
  .ant-notification-notice {
    width: 350px !important;
    margin-left: 25px !important;
  }
  .ant-notification-notice:first-of-type {
    margin-top: 50px !important;
  }
  .ant-notification-notice-message {
    font-size: 24px !important;
  }
  .ant-notification-notice-description {
    font-size: 20px !important;
  }
`;
document.head.appendChild(style);

notification.config({
  duration: 3,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
